/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Divider,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { graphql, StaticQuery } from "gatsby"
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import Logo from "../../../../../../assets/images/elseink.png"
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  logoContainer: {
    width: 200,
    height: 50,
    [theme.breakpoints.up('md')]: {
      margin: 15,
      width: 300,
      height: 70,
    },
  },
  navLink: {
    color: 'rgba(0,0,0,.6)',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    marginRight: theme.spacing(8),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
  },
}));

const SidebarNav = props => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();

  const landings = pages.landings;
  const supportedPages = pages.pages;
  const account = pages.account;

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding>
        <ListItem   >
          <Typography
            variant="body2"
            component={'a'}
            href={"/"}
            className={clsx(classes.navLink, 'submenu-item')}
          >
            Accueil
                  </Typography>
        </ListItem>
        <ListItem   >
          <Typography
            variant="body2"
            component={'a'}
            href={"/oeuvres"}
            className={clsx(classes.navLink, 'submenu-item')}
          >
            Œuvres
                  </Typography>
        </ListItem>
        <ListItem   >
          <Typography
            variant="body2"
            component={'a'}
            href={"/about"}
            className={clsx(classes.navLink, 'submenu-item')}
          >
            Le concept
                  </Typography>
        </ListItem>
      </List>
    );
  };

  const Artistes = props => {
    const { item } = props;
    return (
      <StaticQuery
        query={graphql`
    query queryartistes {
      allMarkdownRemark (filter: {frontmatter: {type: {regex: "/artiste/"}}}){
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              nom
            }
          }
        }
      }
    }
    
    `}
        render={data => {
          return (

            <List disablePadding>
              {data.allMarkdownRemark.edges.map(function (item, i) {
                const path = item.node.fileAbsolutePath.split("/");
                var artisteFolder = "/" + path[path.length - 2];
                console.log(artisteFolder);
                //var artisteFolder = "./artiste";
                return <ListItem key={i} >
                  <Typography
                    variant="body2"
                    component={'a'}
                    href={artisteFolder}
                    className={clsx(classes.navLink, 'submenu-item')}
                  >
                    {item.node.frontmatter.nom}
                  </Typography>

                </ListItem>
              }
              )}
            </List>

          )
        }}
      />
    );
  };

  const LandingPages = () => {
    const { services, apps, web } = landings.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={services} />
        </div>
      </div>
    );
  };

  const SupportedPages = () => {
    const {
      career,
      helpCenter,
      company,
      contact,
      blog,
      portfolio,
    } = supportedPages.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <Artistes />
        </div>
      </div>
    );
  };

  const AccountPages = () => {
    const { settings, signup, signin, password, error } = account.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <ListItem   >
            <Typography
              variant="body2"
              component={'a'}
              href={"/oeuvres"}
              className={clsx(classes.navLink, 'submenu-item', 'snipcart-customer-signin')}
              onClick={() => onClose()}
            >
              Mon compte
                  </Typography>
          </ListItem>
          <ListItem   >
            <Typography
              variant="body2"
              component={'a'}
              href={"/oeuvres"}
              className={clsx(classes.navLink, 'submenu-item', 'snipcart-checkout')}
              onClick={() => onClose()}
            >
              Panier
                  </Typography>
          </ListItem>
        </div>
      </div>
    );
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>




      <ListItem className={classes.closeIcon} onClick={() => onClose()}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>

      <ListItem className={classes.listItem}>
        <div className={classes.logoContainer} >
          <a href="/" title="thefront">
            <Image
              className={classes.logoImage}
              src={Logo}
              alt="elseink"
              lazy={false}
            />
          </a>
        </div>
      </ListItem>

      <ListItem className={classes.listItem}>
        <LandingPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Artistes
        </Typography>
        <SupportedPages />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <AccountPages />
      </ListItem>

    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
